.layer {
    position:absolute;
    right:calc(100% - 2px);
    height:100%;
    width:100%;
    background:linear-gradient(#ccfbff, #ef96c5);
    z-index:1;
    transition:0.5s;
}
.card:hover .layer{
    right:0;
    color: #fff
  }

  .card{
    position:relative;
    margin:0 auto;
    box-sizing:border-box;
    text-align:center;
    box-shadow:0 10px 40px rgba(0,0,0,.5);
    overflow:hidden;
    cursor: pointer;
  }
  .card:hover {
    transform: scale(1.1);
  }

  .card::before, .card::after{
    content:"";
    position: absolute;
    transition: all 0.2s linear;
    background: black;
  }

  .effect::before, .effect::after{
    content:"";
    width:2px;
    height:0;
    position: absolute;
    transition: all 0.2s linear;
    background: black;
  }
  .card:hover::before, .card:hover::after{
    width: 100%;
  }
  .card:hover .card::before, .card:hover .effect::after{
    height: 100%;
  }
  .card::before, .btn-1::after{
    transition-delay: 0.2s;
  }
  .card .effecet::before, .card .effect::after{
    transition-delay: 0s;
  }
  .card::before{
    right: 0;
    top: 0;
  }
  .card::after{
    left: 0;
    bottom: 0;
  }
  .card .effect::before{
    left: 0;
    top: 0;
  }
  .card .effect::after{
    right: 0;
    bottom: 0;
  }
  .card:hover::before, .card:hover::after{
    transition-delay: 0s;
  }
  .card:hover .effect::before, .card:hover .effect::after{
    transition-delay: 0.2s;
  }
  .caption > td {
    font-weight: 700;
  }
  
  td {
    text-align: center;
    padding: 0.3vw;
    font-size: 0.95vw;
  }