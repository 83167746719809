.CancelPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    align-items: stretch;
}

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 20px;
    overflow: scroll;
    height: 45%;
}

.leftContainer {
    padding-left: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.leftContainer .inputGroup {
    display: flex;
    flex-direction: column;
}

.leftContainer .inputGroup label {
    color: gray; 
}

.leftContainer .inputGroup fieldset {
    border: 1px solid #000;
    padding: 10px;
    margin: 10px 0;
}

.leftContainer .inputGroup fieldset legend {
    padding: 0 5px;
    font-size: 0.8em;
}

.leftContainer .inputGroup input {
    border: none;
    box-sizing: border-box;
    width: 100%;
}

.rightContainer {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: stretch; 
    border-radius: 5px;
    padding: 0; 
    width: 235px; 
}

.rightContainer button {
    border: 1px solid #000; 
    width: 100%; 
    padding: 8px 0; 
    margin: 0; 
    box-sizing: border-box; 
    text-align: center;
    transition: background-color 0.2s ease-in-out;
}

.rightContainer button:nth-child(1) { 
    background-color: #717D8C; 
    border-radius: 5px 5px 0 0;
    border-bottom: none;
}

.rightContainer button:nth-child(1):hover {
    background-color: #333030;
}

.rightContainer button:nth-child(2) { 
    background-color: #f4eded; 
    border-bottom: none;
}

.rightContainer button:nth-child(2):hover {
    background-color: #FFFFFF;
}

.rightContainer button:nth-child(3) { 
    background-color: #717D8C; 
    border-radius: 0 0 5px 5px;
    color: white;
}

.rightContainer button:nth-child(3):hover {
    background-color: #007BFF;
}

.dataTable {
    margin-top: 5px;
    width: 100%;
    overflow: auto;
}

.dataTable th {
    font-weight: bold;
}

.inputGroup label {
    color: gray; 
    margin-bottom: 5px;
}
